import {nao_autorizado, pegaDados, processaArquivo} from "../../services/GenericAPI";

let url = 'relatorio'
const state = {
}
const getters = {
}
const actions = {
   async getPercentualCC({commit},{filtro,onSucesso,onErro}) {
      try {
         const list = (await pegaDados(url + '/get_percentual_cc', filtro)).data
         onSucesso(list)
      } catch (error) {
         nao_autorizado(error)
         onErro()
      }
   },
   async getSaldo({commit},{filtro,onSucesso,onErro}) {
      try {
         const list = (await pegaDados(url + '/get_saldo_faturamento', filtro)).data
         onSucesso(list[0])
      } catch (error) {
         nao_autorizado(error)
         onErro()
      }
   },
   async getProximasDespesas({commit},{filtro,onSucesso,onErro}) {
      try {
         const list = (await pegaDados(url + '/get_proximas_despesas', filtro)).data
         onSucesso(list)
      } catch (error) {
         nao_autorizado(error)
         onErro()
      }
   },
   async getUltimasDespesas({commit},{filtro,onSucesso,onErro}) {
      try {
         const list = (await pegaDados(url + '/get_ultimas_despesas', filtro)).data
         onSucesso(list)
      } catch (error) {
         console.log(error);
         nao_autorizado(error)
         onErro()
      }
   },
   async getGraficoReceitasDespesasCC({commit},{filtro,onSucesso,onErro}) {
      try {
         const list = (await pegaDados(url + '/get_receitas_despesas_cc', filtro)).data
         onSucesso(list)
      } catch (error) {
         nao_autorizado(error)
         onErro()
      }
   },

   async printPdf({commit}, {link,filtro,onSucesso, onErro}) {
      try {
         const list = (await processaArquivo(url + '/'+link, filtro))
         onSucesso(list)
      } catch (error) {
         console.log('Erro',error)
         onErro()
      }
   },
}
const mutations = {
}

export default {
   namespaced: true,
   state,
   getters,
   actions,
   mutations
}
