/* eslint-disable */
import {lista, nao_autorizado, pegaDados, processar} from '../../services/GenericAPI'
import {Filtro} from "../../models/filtro/filtro";
import {CentroAtivoRecurso} from '../../models/centro_ativo_recurso'
import {CentroAtivoRecursoSCH, getCentroAtivoRecursoSCH} from "../../search/CentroAtivoRecursoSCH";
import {ContaSCH} from "../../search/ContaSCH";

const url = '/formas_pagamento'

const state = {
  allList: [],
  all: [
    {fpagto: 'DIN', descricao: 'Dinheiro'},
    {fpagto: 'CDR', descricao: 'Cartão de Crédito'},
    {fpagto: 'CDE', descricao: 'Cartão de Débito'},
    {fpagto: 'PIX', descricao: 'Pix'},
    {fpagto: 'DCO', descricao: 'Débito em Conta'},
    {fpagto: 'CHE', descricao: 'Cheque'},
  ],
  filtro: new Filtro()
}

const getters = {
  listaFormasPagamento: (state) => {
    return state.all
  },
  pegaFiltro: (state) => {
    return state.filtro
  }
}

const actions = {
  searchItem({commit}, search) {
    state.filtro.filters.search = [];
    state.filtro.filters.addSearch(['descricao'], '%' + search + '%', 'ilike')
  },
  async getItem({commit}, {centro_ativo_recursos_id, onSucesso,onErro}) {
    try {
      var filtro = getCentroAtivoRecursoSCH('centro_ativo_recurso_id', centro_ativo_recursos_id, '=')
      const list = (await pegaDados(url+'/get', filtro)).data
      if (list.data[0] !== undefined) {
        commit('SET_CENTRO_ATIVO', list.data[0])
        onSucesso()
      } else {
        onErro()
      }
    } catch (error) {
      onErro()
      console.log(error)
    }
  },

  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', CentroAtivoRecursoSCH(perPage))
  },


  async setAllFormasPagamento({commit},{onSucesso, onErro}) {
    try {
      const list = (await pegaDados(url + '/get', state.filtro)).data
      commit('SET_FORMAS_PAGAMENTOS', list)
      onSucesso()
    } catch (error) {
      console.log(error)
      onErro()
      nao_autorizado(error)
    }
  },
  // async processarFormaPagamento({commit}, {acao,onSucesso,onErro}) {
  //   try {
  //     let res = await processar(url + (acao === 'gravar' ? '/commit' : '/delete'), state.centro_ativo_recurso, acao)
  //
  //     this.dispatch('geral/setMsgSuccess', res.data.message)
  //     onSucesso()
  //   } catch (error) {
  //     onErro()
  //     this.dispatch('geral/setObjMsgError', error.response.data)
  //   }
  // },
  // setFormaPagamento({commit}, centro_ativo_recurso) {
  //   commit('SET_CENTRO_ATIVO', centro_ativo_recurso)
  // },
}

const mutations = {

  SET_ALL_LIST(state, filtro) {
    state.allList = filtro
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  },
  SET_FORMAS_PAGAMENTOS(state, centro_ativo_recursos) {
    state.all = centro_ativo_recursos
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
